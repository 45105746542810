import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import './App.css';
import './App.scss';
// import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
// import reportWebVitals from './reportWebVitals';
import {
  createHashRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import intl from 'react-intl-universal';

import ErrorPage from './error-page';
import Login from './routes/login';
import Devices from './routes/devices';
import CreateAccount from './routes/createaccount';
import ForgottenPasswordSend from './routes/forgottenpasswordsend';
import EditUser from './routes/edituser';
import RegisterDevice from './routes/registerdevice';
import EditDevice from './routes/editdevice';
import Users from './routes/users';
import Device from './routes/device';
import Forgottenpasswordrestore from './routes/forgottenpasswordrestore';

const locales = {
  'en-US': {
    ...(require('./translations/routes/login/en.json')),
    ...(require('./translations/routes/createaccount/en.json')),
    ...(require('./translations/routes/forgottenpassword/en.json')),
    ...(require('./translations/routes/forgottenpasswordrestore/en.json')),
    ...(require('./translations/routes/edituser/en.json')),
    ...(require('./translations/routes/registerdevice/en.json')),
    ...(require('./translations/routes/editdevice/en.json')),
    ...(require('./translations/routes/devices/en.json')),
    ...(require('./translations/routes/users/en.json')),
    ...(require('./translations/components/mymenu/en.json')),
    ...(require('./translations/components/loginform/en.json')),
    ...(require('./translations/components/userform/en.json')),
    ...(require('./translations/components/devicename/en.json')),
  },
  'cs-CZ': {
    ...(require('./translations/routes/login/cs.json')),
    ...(require('./translations/routes/createaccount/cs.json')),
    ...(require('./translations/routes/forgottenpassword/cs.json')),
    ...(require('./translations/routes/forgottenpasswordrestore/cs.json')),
    ...(require('./translations/routes/edituser/cs.json')),
    ...(require('./translations/routes/registerdevice/cs.json')),
    ...(require('./translations/routes/editdevice/cs.json')),
    ...(require('./translations/routes/devices/cs.json')),
    ...(require('./translations/routes/users/cs.json')),
    ...(require('./translations/components/mymenu/cs.json')),
    ...(require('./translations/components/loginform/cs.json')),
    ...(require('./translations/components/userform/cs.json')),
    ...(require('./translations/components/devicename/cs.json')),
  },
};

let currentLocale = intl.determineLocale({
  urlLocaleKey: 'lang', // Example: https://fe-tool.com/react-intl-universal?lang=en-US
  cookieLocaleKey: 'lang', // Example: "lang=en-US" in cookie
});

// 2. Fallback to "en-US" if the currentLocale isn't supported in LOCALES_LIST
if (!locales[currentLocale]) {
  // currentLocale = "en-US"
  currentLocale = "cs-CZ"
}

currentLocale = "cs-CZ"; // TESTING

intl.init({
  currentLocale,
  locales: locales,
});

async function loadMy() {
  const res = await fetch("api/my", {
      method: 'GET',
      cache: 'no-cache',
      credentials: "include"
    });
    if (res.status !== 200){
      console.log('Error:', res);
      throw new Response("Fail", { status: res.status });
    }
    return res.json();

  // .then((user) => {
  //   console.log('Success:', user);
  //   if (user.sessionId) {
  //     this.setState({ user: user });
  //     this.loadDevices();
  //   } else {
  //     this.props.navigate('/login');
  //   }
  // })
  // .catch((error) => {
  //   console.log('Error:', error);
  //   this.props.navigate('/login');
  // });
}

const router = createHashRouter([{
    loader: loadMy,
    path: "/",
    element: < Devices / > ,
    errorElement: <Navigate to="/login" replace={true} /> ,
  },
  {
    path: "login",
    element: < Login / > ,
  },
  {
    path: "createaccount",
    element: < CreateAccount / > ,
  },
  {
    path: "createaccount/:email",
    element: < CreateAccount / > ,
  },
  {
    path: "forgottenpasswordsend",
    element: < ForgottenPasswordSend / > ,
  },
  {
    loader: loadMy,
    path: "edituser",
    element: < EditUser / > ,
    errorElement: <Navigate to="/login" replace={true} />
  },
  {
    loader: loadMy,
    path: "registerdevice",
    element: < RegisterDevice / > ,
    errorElement: <Navigate to="/login" replace={true} />
  },
  {
    loader: loadMy,
    path: "editdevice/:deviceId",
    element: < EditDevice / > ,
    errorElement: <Navigate to="/login" replace={true} />
  },
  {
    loader: loadMy,
    path: "device/:deviceSn",
    element: < Device / > ,
    errorElement: <Navigate to="/login" replace={true} />
  },
  {
    loader: loadMy,
    path: "users",
    element: < Users / > ,
    errorElement: <Navigate to="/login" replace={true} />
  },
  {
    path: "forgottenpasswordrestore/:restoreId",
    element: < Forgottenpasswordrestore / >
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode > 
    <RouterProvider router = {router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
{/* reportWebVitals(); */}