import React, { Component } from "react";
import { Form } from 'react-bootstrap';
import intl from 'react-intl-universal';
import LoginForm from "./loginform";


class UserForm extends Component {
    render() {
        return (
            <>
                <LoginForm email={this.props.email} password={this.props.password} emailChange={this.props.emailChange} passwordChange={this.props.passwordChange} emailValid={this.props.emailValid} passwordValid={this.props.passwordValid} />

                <Form.Group className="mb-3">
                    <Form.Label>{intl.get("components.userform.name")}</Form.Label>
                    <Form.Control type="text" value={this.props.name} onChange={(e, v) => this.props.nameChange(e, v)} />
                </Form.Group>
            </>
        )
    }
}

export default UserForm;