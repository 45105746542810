import React, { Component } from "react";
import Usermenu from "../components/mymenu";
import { withRouter } from '../withRouter';

class Device extends Component {

  constructor(props) {
    super(props);

    this.iframeRef = React.createRef();
  }

  render() {
    return (
      <>
        <Usermenu user={this.props.loader} />

        {this.props.loader &&
            <iframe ref={this.iframeRef} title="device-frame" name="device" className="device-iframe" src={encodeURI(window.rtcGatewayConfig.deviceHostnamePattern.replace('%s', this.props.params.deviceSn) + '?addatSessionId=' + this.props.loader.sessionId + '&_t=' + Date.now())} />
        }
      </>
    );
  }
}

export default withRouter(Device);