import React, { Component } from "react";
import { Navbar, Nav, Container } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faHouse } from '@fortawesome/free-solid-svg-icons'
import intl from 'react-intl-universal';

class MyMenu extends Component {

    logout() {
        fetch("api/logout", {
            method: 'GET',
            cache: 'no-cache',
            credentials: "include",
        })
            .then(() => this.props.navigate('/login'))
    }

    roleName(roleId){
        switch(roleId){
            case 2: return "("+intl.get("components.mymenu.admin")+")";
            case 1: return "("+intl.get("components.mymenu.service")+")";
            default: return "";
        }
    }


    render() {
        return (
            <>
                {this.props.user && <>
                    <Navbar>
                        <Container>
                            <Navbar.Brand>
                                <Nav.Link onClick={() => this.props.navigate('/')}><FontAwesomeIcon icon={faHouse} /></Nav.Link>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    {this.props.user.role === 2 && <Nav.Link onClick={() => this.props.navigate('/users')} >{intl.get("components.mymenu.users")}</Nav.Link>}
                                </Nav>
                                <Nav>
                                    <Navbar.Text>{this.props.user.name} {this.roleName(this.props.user.role)}</Navbar.Text>
                                    <Nav.Link onClick={() => this.props.navigate('/edituser')}><FontAwesomeIcon icon={faGear} /></Nav.Link>
                                    <Nav.Link onClick={this.logout.bind(this)} >{intl.get("components.mymenu.logout")}</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </>}
            </>
        );
    }
}

export default withRouter(MyMenu);