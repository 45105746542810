import React, { Component } from "react";
import { Button, Form, Alert, Row, Col } from 'react-bootstrap';
import Usermenu from "../components/mymenu";
import { withRouter } from '../withRouter';
import intl from 'react-intl-universal';
import UserForm from '../components/userform';
import { isValidEmail, isValidPassword } from "../utils/helper";

class EditUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name: "",
      message: "",
    };
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser() {
    fetch("api/user", {
      method: 'GET',
      cache: 'no-cache',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        if (response.status === 401) {
          this.props.navigate('/login');
          return;
        }
        console.log('Error:', "network error");
      })
      .then((user) => {
        console.log('Success:', user);
        if (user) {
          console.log('Success:', user);
          this.setState({ ...{ password: "" }, ...user });
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }


  saveUser() {
    fetch("api/user", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'email': this.state.email, 'password': this.state.password, 'name': this.state.name })
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        if (response.status === 401) {
          this.props.navigate('/login');
          return;
        }
        console.log('Error:', "network error");
      })
      .then((user) => {
        console.log('Success:', user);
        this.setState({ ...{ password: "" }, ...user });
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  render() {
    return (
      <>
        <Usermenu user={this.props.loader} />
        <div className="app-content">
          <h1>{intl.get("routes.edituser.editUser")}</h1>
          {this.state.message &&
            <Alert variant="danger">{this.state.message}</Alert>
          }
          <Form>
            <UserForm email={this.state.email} password={this.state.password} name={this.state.name}
              emailChange={(e, v) => this.setState({ email: e.target.value })}
              passwordChange={(e, v) => this.setState({ password: e.target.value })}
              nameChange={(e, v) => this.setState({ name: e.target.value })}
              emailValid={isValidEmail(this.state.email)}
              passwordValid={isValidPassword(this.state.password)} />

            <Row className="align-items-center">
              <Col xs="auto">
                <Button className="mb-2" variant="secondary" onClick={() => this.props.navigate('/')}><Form.Label>{intl.get("routes.edituser.back")}</Form.Label></Button>
              </Col>
              {this.state.email &&
                <Col xs="auto">
                  <Button className="mb-2" variant="primary" onClick={this.saveUser.bind(this)}><Form.Label>{intl.get("routes.edituser.save")}</Form.Label></Button>
                </Col>
              }
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default withRouter(EditUser);