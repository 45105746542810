import React, { Component } from "react";
import { Button, Form, Alert, Row, Col } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import intl from 'react-intl-universal';
import LoginForm from "../components/loginform";
import { isValidEmail, isValidPassword } from "../utils/helper";

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      message: "",
    };
  }

  loadDevices() {
    fetch("api/devices", {
      method: 'GET',
      cache: 'no-cache',
      credentials: "include",
    })
      .catch((error) => {
        this.props.navigate('/');
      })
      .then((response) => response.json())
      .catch((error) => {
        this.props.navigate('/');
      })
      .then((devices) => {
        console.log('Success:', devices);
        if (devices.length === 1 && ((Date.now() - devices[0].lastActivity) < 15 * 60 )){
          this.props.navigate('/device/' + devices[0].id);
        } else {
          this.props.navigate('/');
        }
      })
      .catch((error) => {
        this.props.navigate('/');
      });
  }

  login() {
    fetch("api/login", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'email': this.state.email, 'password': this.state.password })
    })
      .then((response) => response.json())
      .then((user) => {
        console.log('Success:', user);
        if (user.authorized) {
          console.log('cookies:', document.cookie);
          this.loadDevices();
        } else {
          this.setState({ message: user.message, invalidEmailOrPassword: user.invalidEmailOrPassword })
        }
      })
      .catch((error) => {
        console.log('Error:', error);
        this.setState({ user: {} });
      });
  }

  render() {
    return (
      <>
        <div className="app-content">
          <h1>{intl.get("routes.login.login")}</h1>
          {this.state.message &&
            <Alert variant="danger">{this.state.message}</Alert>
          }
          {this.state.invalidEmailOrPassword &&
            <Alert variant="danger">{intl.get("routes.login.invalidEmailOrPassword")}</Alert>
          }
          <Form onSubmit={this.login.bind(this)}>
            <LoginForm email={this.state.email} password={this.state.password} 
            emailChange={(e, v) => this.setState({ email: e.target.value })} passwordChange={(e, v) => this.setState({ password: e.target.value })}
            emailValid={isValidEmail(this.state.email)} passwordValid={isValidPassword(this.state.password)} />

            <Row className="align-items-center">
              <Col xs="auto">
                <Button type="submit" className="mb-2" variant="primary" onClick={this.login.bind(this)}><Form.Label>{intl.get("routes.login.login")}</Form.Label></Button>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col xs="auto">
                <Button className="mb-2" variant="secondary" onClick={() => this.props.navigate("/createaccount")}><Form.Label>{intl.get("routes.login.createAccount")}</Form.Label></Button>
              </Col>
              <Col xs="auto">
                <Button className="mb-2" variant="secondary" onClick={() => this.props.navigate("/forgottenpasswordsend")}><Form.Label>{intl.get("routes.login.forgottenPassword")}</Form.Label></Button>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default withRouter(Login);