import React, { Component } from "react";
import { Form } from 'react-bootstrap';
import intl from 'react-intl-universal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'


class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordBoxType: "password"
        };
    }

    render() {
        return (
            <>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{intl.get("components.loginform.email")}</Form.Label>
                    <Form.Control type="email" value={this.props.email} onChange={(e, v) => this.props.emailChange(e, v)} isInvalid={!this.props.emailValid} />
                    {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>{intl.get("components.loginform.password")}</Form.Label> <FontAwesomeIcon
                        icon={this.state.passwordBoxType === "password" ? faEyeSlash : faEye}
                        onClick={() => this.setState({ passwordBoxType: this.state.passwordBoxType === "password" ? "text" : "password" })} />
                    <Form.Control type={this.state.passwordBoxType} value={this.props.password} onChange={(e, v) => this.props.passwordChange(e, v)} isInvalid={!this.props.passwordValid} />
                </Form.Group>

            </>
        )
    }
}

export default LoginForm;