import React, { Component } from "react";
import { Form } from 'react-bootstrap';
import intl from 'react-intl-universal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';


class DeviceName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameHelp: false,
        };
        this.nameRef = React.createRef();
    }

    nameChanged(e, v) {
        if (this.props.nameChanged) {
            this.props.nameChanged(e, v);
        }
    }
    render() {
        return (
            <Form.Group className="mb-3">
                <Form.Label>{intl.get("components.devicename.name")} ({intl.get("components.devicename.nameinfo")}) <FontAwesomeIcon icon={faCircleQuestion} ref={this.nameRef} onClick={() => this.setState({ nameHelp: !this.state.nameHelp })} /></Form.Label>
                <Form.Control type="text" value={this.props.name} onChange={this.nameChanged.bind(this)} />
                <Overlay target={this.nameRef} show={this.state.nameHelp} placement="top">
                    {(props) => (
                        <Tooltip {...props} onClick={() => this.setState({ nameHelp: false })}>
                            {intl.get("components.devicename.namehelp")}
                        </Tooltip>
                    )}
                </Overlay>
            </Form.Group>
        )
    }
}

export default DeviceName;