import React, { Component } from "react";
import { Button, Form, Alert, Row, Col } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import intl from 'react-intl-universal';
import UserForm from "../components/userform";
import { isValidEmail, isValidPassword } from "../utils/helper";

class CreateAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name: "",
      message: "",
      emailOccupied: false,
      sessionId: "",
      sessionA: 0,
      sessionB: 0,
    };
  }

  componentDidMount() {
    this.getCreateSession();
    if (this.props.params.email && !this.state.email) {
      this.setState({ email: this.props.params.email })
    }
  }

  getCreateSession() {
    fetch("api/createUserSession", {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((session) => {
        console.log('Success:', session);
        this.setState({ sessionId: session.id, sessionA: parseInt(session.a), sessionB: parseInt(session.b) })
      })
      .catch((error) => {
        console.log('Error:', error);
        this.setState({ sessionId: "" });
      });
  }

  createAccount() {
    fetch("api/createUser", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'email': this.state.email, 'password': this.state.password, 'name': this.state.name, 'id': this.state.sessionId, 'c': this.state.sessionA + this.state.sessionB })
    })
      .then((response) => response.json())
      .then((user) => {
        console.log('Success:', user);
        if (user.userSessionId) {
          console.log('cookies:', document.cookie);
          this.props.navigate('/');
        } else {
          this.setState({ message: user.message, emailOccupied: user.emailOccupied })
        }
        this.getCreateSession();
      })
      .catch((error) => {
        console.log('Error:', error);
        this.setState({ user: {} });
        this.getCreateSession();
      });
  }

  render() {
    return (
      <>
        <div className="app-content">
          <h1>{intl.get("routes.createaccount.createAccount")}</h1>
          {this.state.message &&
            <Alert variant="danger">{this.state.message}</Alert>
          }
          {this.state.emailOccupied &&
            <Alert variant="danger">{intl.get("routes.createaccount.emailOccupied")}</Alert>
          }
          <Form>
            <UserForm email={this.state.email} password={this.state.password} name={this.state.name}
              emailChange={(e, v) => this.setState({ email: e.target.value })}
              passwordChange={(e, v) => this.setState({ password: e.target.value })}
              nameChange={(e, v) => this.setState({ name: e.target.value })}
              emailValid={isValidEmail(this.state.email)}
              passwordValid={isValidPassword(this.state.password)} />

            <Row className="align-items-center">
              <Col xs="auto">
                <Button className="mb-2" variant="secondary" onClick={() => this.props.navigate('/')}><Form.Label>{intl.get("routes.createaccount.back")}</Form.Label></Button>
              </Col>
              {this.state.sessionId &&
                <Col xs="auto">
                  <Button className="mb-2" variant="primary" onClick={this.createAccount.bind(this)}><Form.Label>{intl.get("routes.createaccount.createAccount")}</Form.Label></Button>
                </Col>
              }
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default withRouter(CreateAccount);