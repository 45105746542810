import React, { Component } from "react";
import { Button, Form, Alert, Row, Col } from 'react-bootstrap';
import Usermenu from "../components/mymenu";
import { withRouter } from '../withRouter';
import intl from 'react-intl-universal';
import DeviceName from "../components/devicename";


class RegisterDevice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sn: "",
      name: "",
      registerResponse: {}
    };
  }

  register() {
    fetch("api/registerDevice", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'sn': this.state.sn, 'name': this.state.name })
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        if (response.status === 401) {
          this.props.navigate('/login');
          return;
        }
        console.log('Error:', "network error");
      })
      .then((device) => {
        console.log('Success:', device);
        if (device.registered) {
          this.props.navigate('/');
        } else {
          this.setState({ registerResponse: { ...{}, ...device } })
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  render() {
    return (
      <>
        <Usermenu user={this.props.loader} />
        <div className="app-content">
          <h1>{intl.get("routes.registerdevice.registerDevice")}</h1>
          {this.state.registerResponse.message &&
            <Alert variant="danger">{this.state.message}</Alert>
          }
          {this.state.registerResponse.used &&
            <Alert variant="danger">{intl.get("routes.registerdevice.used")}</Alert>
          }
          {this.state.registerResponse.notFound &&
            <Alert variant="danger">{intl.get("routes.registerdevice.notfound")}</Alert>
          }
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>{intl.get("routes.registerdevice.sn")}</Form.Label>
              <Form.Control type="text" value={this.state.sn} onChange={(e, v) => this.setState({ sn: e.target.value })} />
            </Form.Group>

            <DeviceName name={this.state.name} nameChanged={(e, v) => this.setState({ name: e.target.value })} />

            <Row className="align-items-center">
              <Col xs="auto">
                <Button className="mb-2" variant="secondary" onClick={() => this.props.navigate('/')}><Form.Label>{intl.get("routes.registerdevice.back")}</Form.Label></Button>
              </Col>
              <Col xs="auto">
                <Button className="mb-2" variant="primary" onClick={this.register.bind(this)}><Form.Label>{intl.get("routes.registerdevice.register")}</Form.Label></Button>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default withRouter(RegisterDevice);