import React, { Component } from "react";
import { Button, Form, Alert, Row, Col } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import intl from 'react-intl-universal';
import { isValidEmail } from "../utils/helper";

class ForgottenPasswordSend extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      info: "",
    };
  }

  send() {
    fetch("api/forgottenPasswordSend?" + new URLSearchParams({ email: this.state.email }), {
      method: 'GET',
      cache: 'no-cache'
    })
      .then((response) => {
        if (response.ok) {
          this.setState({ info: intl.get("routes.forgottenpassword.sent"), message: "" })
        } else {
          this.setState({ message: intl.get("routes.forgottenpassword.failedSend"), info: "" })
        }
      })
      .catch((error) => {
        this.setState({ message: intl.get("routes.forgottenpassword.failedSend"), info: "" })
        console.log('Error:', error);
      });
  }

  render() {
    return (
      <>
        <div className="app-content">
          <h1>{intl.get("routes.forgottenpassword.forgottenPassword")}</h1>
          {this.state.message &&
            <Alert variant="danger">{this.state.message}</Alert>
          }
          {this.state.info &&
            <Alert variant="success">{this.state.info}</Alert>
          }
          <p>
          {intl.get("routes.forgottenpassword.sendPasswordHelp")}
          </p>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{intl.get("routes.forgottenpassword.email")}</Form.Label>
              <Form.Control type="email" value={this.state.email} onChange={(e, v) => this.setState({ email: e.target.value })} isInvalid={!isValidEmail(this.state.email)} />
              {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>

            <Row className="align-items-center">
              <Col xs="auto">
                <Button variant="secondary" onClick={() => this.props.navigate('/')}><Form.Label>{intl.get("routes.forgottenpassword.back")}</Form.Label></Button>
              </Col>
              <Col xs="auto">
                <Button variant="primary" onClick={this.send.bind(this)}><Form.Label>{intl.get("routes.forgottenpassword.sendPassword")}</Form.Label></Button>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default withRouter(ForgottenPasswordSend);