import React, { Component } from "react";
import { Button, Form, InputGroup, NavLink, Table } from 'react-bootstrap';
import Usermenu from "../components/mymenu";
import { withRouter } from '../withRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faGear, faGlobe, faAppleWhole } from '@fortawesome/free-solid-svg-icons'
import intl from 'react-intl-universal';

class Devices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      devices: []
    };
  }

  componentDidMount() {
    this.loadDevices();
  }

  loadDevices() {
    fetch("api/devices", {
      method: 'GET',
      cache: 'no-cache',
      credentials: "include",
    })
      .catch((error) => {
        console.log('Error:', error);
      })
      .then((response) => response.json())
      .catch((error) => {
        console.log('Error:', error);
      })
      .then((devices) => {
        console.log('Success:', devices);
        this.setState({ devices: devices });
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }


  render() {
    const re = new RegExp(this.state.search, "i");
    var devices = this.state.devices.filter((d) => (d.sn && d.sn.match(re)) || (d.name && d.name.match(re))).map((device) => {
      // var isOnline = ((Date.now() / 1000) - device.lastActivity) < (15 * 60);
      return (
        <tr key={device.id}>
          <td>
            {device.name ? (device.name + ' (' + device.sn + ')') : device.sn}
          </td>
          {/* <td>
            <FontAwesomeIcon icon={faGlobe} color={isOnline ? "green" : "red"} /> {isOnline ? "" : intl.get("routes.devices.offline")}
          </td> */}
          <td>
            <NavLink onClick={() => this.props.navigate('/editdevice/' + device.id)}>
              <FontAwesomeIcon icon={faGear} />
            </NavLink>
          </td>
          <td>
            {/* {window.rtcGatewayConfig && <Button onClick={() => this.props.navigate('/device/' + device.sn)}>{intl.get("routes.devices.connect")}</Button>} */}
            {window.rtcGatewayConfig &&
              <Form className="inline" action={window.rtcGatewayConfig.deviceHostnamePattern.replace('%s', device.sn)} method="POST">
                <input type="hidden" name="addatSessionId" value={this.props.loader.sessionId} />
                <Button type="submit" >{intl.get("routes.devices.connect")}</Button>
                {/* <Button type="submit" ><FontAwesomeIcon icon={faAppleWhole}></FontAwesomeIcon></Button> */}
              </Form>
            }
          </td>
        </tr>
      )
    });

    return (
      <>
        <Usermenu user={this.props.loader} />

        <div className="app-content">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
            <Form.Control
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={this.state.search} onChange={(e, v) => this.setState({ search: e.target.value })}
            />
          </InputGroup>

          <Button onClick={() => this.props.navigate('/registerdevice')}>{intl.get("components.mymenu.registerdevice")}</Button>

          <Table responsive >
            <tbody>
              {devices}
            </tbody>
          </Table>
        </div>

      </>
    );
  }
}

export default withRouter(Devices);