import React, { Component } from "react";
import { Button, Form, Alert, Row, Col } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import intl from 'react-intl-universal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

class ForgottenPasswordRestore extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: "",
      password: "",
      passwordBoxType: "password",
    };
  }

  restore() {
    fetch("api/forgottenPasswordRestore", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'password': this.state.password, 'restoreId': this.props.params.restoreId })
    })
      .then((response) => response.json())
      .then((user) => {
        console.log('Success:', user);
        if (user.invalidPassword) {
          this.setState({ message: intl.get("routes.forgottenpasswordrestore.invalidPassword") })
          return;
        }
        if (user.invalidRestoreId) {
          this.setState({ message: intl.get("routes.forgottenpasswordrestore.invalidRestoreId") })
          return
        }

        if (user.authorized) {
          console.log('cookies:', document.cookie);
          this.props.navigate('/');
        } else {
          this.setState({ message: user.message })
        }
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  render() {
    return (
      <>
        <div className="app-content">
          <h1>{intl.get("routes.forgottenpasswordrestore.forgottenPasswordRestore")}</h1>
          {this.state.message &&
            <Alert variant="danger">{this.state.message}</Alert>
          }
          <p>
            {intl.get("routes.forgottenpasswordrestore.enterNewPassword")}
          </p>

          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>{intl.get("routes.forgottenpasswordrestore.password")}</Form.Label> <FontAwesomeIcon
                icon={this.state.passwordBoxType === "password" ? faEyeSlash : faEye}
                onClick={() => this.setState({ passwordBoxType: this.state.passwordBoxType === "password" ? "text" : "password" })} />
              <Form.Control type={this.state.passwordBoxType} value={this.state.password} onChange={(e, v) => this.setState({ password: e.target.value })} />
            </Form.Group>

            <Row className="align-items-center">
              <Col xs="auto">
                <Button variant="secondary" onClick={() => this.props.navigate('/')}><Form.Label>{intl.get("routes.forgottenpasswordrestore.back")}</Form.Label></Button>
              </Col>
              <Col xs="auto">
                <Button variant="primary" onClick={this.restore.bind(this)}><Form.Label>{intl.get("routes.forgottenpasswordrestore.set")}</Form.Label></Button>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default withRouter(ForgottenPasswordRestore);