import React, { Component } from "react";
import { Button, Form, Alert, Row, Col, Table } from 'react-bootstrap';
import Usermenu from "../components/mymenu";
import { withRouter } from '../withRouter';
import intl from 'react-intl-universal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { isValidEmail } from "../utils/helper";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import DeviceName from "../components/devicename";

class EditDevice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      message: "",
      loaded: false,
      nameHelp: false,
      inviteHelp: false,
      users: []
    };

    this.nameRef = React.createRef();
    this.inviteRef = React.createRef();
  }

  componentDidMount() {
    this.loadDevice();
  }

  loadDevice() {
    fetch("api/device?" + new URLSearchParams({ id: this.props.params.deviceId }), {
      method: 'GET',
      cache: 'no-cache',
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        if (response.status === 401) {
          this.props.navigate('/login');
          return;
        }
        console.log('Error:', "network error");
      })
      .then((device) => {
        console.log('Success:', device);
        if (device) {
          this.setState({ ...{ loaded: true }, ...device });
        }
      })
      .catch((error) => {
        console.log('Error:', error);
        this.setState({ user: {} });
      });
  }

  saveDevice() {
    fetch("api/device?" + new URLSearchParams({ id: this.props.params.deviceId }), {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'name': this.state.name })
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        if (response.status === 401) {
          this.props.navigate('/login');
          return;
        }
        console.log('Error:', "network error");
      })
      .then((device) => {
        console.log('Success:', device);
        this.setState({ ...{}, ...device });
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  invite() {
    fetch("api/invite", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: this.state.email, deviceId: parseInt(this.props.params.deviceId, 10) })
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        }
        if (response.status === 401) {
          this.props.navigate('/login');
          return;
        }
        console.log('Error:', "network error");
      })
      .then((invite) => {
        console.log('Success:', invite);
        this.setState({ ...{}, ...invite });
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  uninvite(userId) {
    fetch("api/uninvite?" + new URLSearchParams({
      userId: userId,
      deviceId: parseInt(this.props.params.deviceId, 10)
    }), {
      method: 'POST',
      cache: 'no-cache'
    })
      .then((response) => {
        if (response.status === 200) {
          for (let i = 0; i < this.state.users.length; i++) {
            if (this.state.users[i].userId === userId) {
              this.state.users.splice(i, 1);
              this.setState({ users: this.state.users });
              break;
            }
          }
          return;
        }
        if (response.status === 401) {
          this.props.navigate('/login');
          return;
        }
        console.log('Error:', "network error");
      })
  }


  render() {
    var users = this.state.users && this.state.users.map((u, i) =>
      <tr key={u.email}>
        <td>{u.email}</td>
        <td>{(u.role > 0) ? intl.get((u.role === 2) ? "routes.editdevice.administrator" : "routes.editdevice.service") : ""}</td>
        <td>
          {u.role <= this.props.loader.role &&
            <Button className="mb-2" variant="primary" onClick={this.uninvite.bind(this, u.userId)}><FontAwesomeIcon icon={faTrash} /></Button>
          }
        </td>
      </tr>
    );

    return (
      <>
        <Usermenu user={this.props.loader} />
        <div className="app-content">
          {this.props.loader && this.props.loader.role > 0 &&
            <>
              <h1>{intl.get("routes.editdevice.editDevice")}</h1>
              {this.state.message &&
                <Alert variant="danger">{this.state.message}</Alert>
              }
              <Form>
                <DeviceName name={this.state.name} nameChanged={(e, v) => this.setState({ name: e.target.value })} />

                <Row className="align-items-center">
                  {this.state.loaded &&
                    <Col xs="auto">
                      <Button className="mb-2" variant="primary" onClick={this.saveDevice.bind(this)}><Form.Label>{intl.get("routes.editdevice.save")}</Form.Label></Button>
                    </Col>
                  }
                </Row>
              </Form>
            </>
          }
          <h1>{intl.get("routes.editdevice.users")}</h1>
          <Table>
            <tbody>
              {users}
            </tbody>
          </Table>


          <Row className="align-items-center">
            <Col xs="auto">
              {intl.get("routes.editdevice.inviteUser")} <FontAwesomeIcon icon={faCircleQuestion} ref={this.inviteRef} onClick={() => this.setState({ inviteHelp: !this.state.inviteHelp })} />
              <Overlay target={this.inviteRef} show={this.state.inviteHelp} placement="top">
                {(props) => (
                  <Tooltip {...props} onClick={() => this.setState({ inviteHelp: false })}>
                    {intl.get("routes.editdevice.inviteUserHelp")}
                  </Tooltip>
                )}
              </Overlay>
            </Col>
            <Col xs="auto">
              <Form.Control type="text" value={this.state.email} onChange={(e, v) => this.setState({ email: e.target.value })} isInvalid={!isValidEmail(this.state.email)} />
            </Col>
            <Col xs="auto">
              <Button className="mb-2" variant="primary" onClick={this.invite.bind(this)}>{intl.get("routes.editdevice.invite")}</Button>
            </Col>
          </Row>
          {this.state.unknownEmail &&
            <>
              <Alert variant="danger">{intl.get("routes.editdevice.unknownUser")}</Alert>
              <Button className="mb-2" variant="primary" onClick={() => this.props.navigate('/createaccount/'+this.state.email)}>{intl.get("routes.editdevice.createNewAccount")}</Button>
            </>
          }

          <Row className="align-items-center">
            <Col xs="auto">
              <Button className="mb-2" variant="secondary" onClick={() => this.props.navigate('/')}><Form.Label>{intl.get("routes.editdevice.back")}</Form.Label></Button>
            </Col>
          </Row>

        </div>
      </>
    );
  }
}

export default withRouter(EditDevice);