import React, { Component } from "react";
import { Button, Form, Table, InputGroup } from 'react-bootstrap';
import Usermenu from "../components/mymenu";
import { withRouter } from '../withRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import intl from 'react-intl-universal';
import { faTrash } from '@fortawesome/free-solid-svg-icons'

class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      user: {},
      users: []
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  loadUsers() {
    fetch("api/users", {
      method: 'GET',
      cache: 'no-cache',
      credentials: "include",
    })
      .catch((error) => {
        console.log('Error:', error);
      })
      .then((response) => response.json())
      .catch((error) => {
        console.log('Error:', error);
      })
      .then((users) => {
        console.log('Success:', users);
        this.setState({ users: users });
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  saveUser(user) {
    fetch("api/changeRole", {
      method: 'POST',
      cache: 'no-cache',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'id': user.id, 'role': user.role })
    })
      .catch((error) => {
        console.log('Error:', error);
      })
      .then((response) => response.json())
      .catch((error) => {
        console.log('Error:', error);
      })
      .then((data) => {
        console.log('Success:', data);
        user.changed = false;
        this.setState({ users: this.state.users });
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  deleteUser(user) {
    fetch("api/deleteUser", {
      method: 'POST',
      cache: 'no-cache',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'id': user.id })
    })
      .catch((error) => {
        console.log('Error:', error);
      })
      .then((response) => response.json())
      .catch((error) => {
        console.log('Error:', error);
      })
      .then((users) => {
        console.log('Success:', users);
        this.setState({ users: users });
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }

  changeRole(user, ev) {
    user.role = parseInt(ev.target.value, 10);
    user.changed = true;
    this.setState({ users: this.state.users })
  }

  render() {
    const re = new RegExp(this.state.search, "i");
    var users = this.state.users && this.state.users.filter((u) => (u.email && u.email.match(re)) || (u.name && u.name.match(re))).map((user) => {
      return (
        <tr key={user.id}>
          <td>
            {user.email}
          </td>
          <td>
            {user.name}
          </td>
          <td>
            <Form.Select aria-label={intl.get("routes.users.role")} value={user.role} onChange={this.changeRole.bind(this, user)}>
              <option value="0">{intl.get("routes.users.user")}</option>
              <option value="1">{intl.get("routes.users.service")}</option>
              <option value="2">{intl.get("routes.users.administrator")}</option>
            </Form.Select>
          </td>
          <td>
            <Button variant={user.changed ? "primary" : "secondary"} onClick={this.saveUser.bind(this, user)} >{intl.get("routes.users.save")}</Button>
          </td>
          <td>
            <Button className="mb-2" variant="primary" onClick={this.deleteUser.bind(this, user)}><FontAwesomeIcon icon={faTrash} /></Button>
          </td>
        </tr>

      )
    });

    return (
      <>
        <Usermenu user={this.state.user} />
        <div className="app-content">
          <h1>{intl.get("routes.users.users")}</h1>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
            <Form.Control
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={this.state.search} onChange={(e, v) => this.setState({ search: e.target.value })}
            />
          </InputGroup>
          <Table responsive>
            <thead>
              <tr>
                <th>{intl.get("routes.users.name")}</th>
                <th>{intl.get("routes.users.email")}</th>
                <th>{intl.get("routes.users.role")}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default withRouter(Users);